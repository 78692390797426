export enum CKElinks2023 {
    maj2024PP = "https://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2023/Arkusze_egzaminacyjne/2024/Matematyka/poziom_podstawowy/MMAP-P0-100-A-2405-arkusz.pdf",
    maj2024PR = "https://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2023/Arkusze_egzaminacyjne/2024/Matematyka/poziom_rozszerzony/MMAP-R0-100-A-2405-arkusz.pdf",
    maj2023PP = "https://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2023/Arkusze_egzaminacyjne/2023/Matematyka/poziom_podstawowy/MMAP-P0-100-2305.pdf",
    maj2023PR = "https://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2023/Arkusze_egzaminacyjne/2023/Matematyka/poziom_rozszerzony/MMAP-R0-100-2305.pdf",
    wzoryMatematyczne = "https://www.cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2023/Informatory/wybrane_wzory_matematyczne_EM2023.pdf",
}
export enum CKElinks2015 {
    maj2024PP = "https://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2024/Matematyka/poziom_podstawowy/EMAP-P0-100-A-2405-arkusz.pdf",
    maj2024PR = "https://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2024/Matematyka/poziom_rozszerzony/EMAP-R0-100-A-2405-arkusz.pdf",
    maj2023PP = "https://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2023/Matematyka/poziom_podstawowy/EMAP-P0-100-2305.pdf",
    maj2023PR = "https://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2023/Matematyka/poziom_rozszerzony/EMAP-R0-100-2305.pdf",
    maj2022PP = "http://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2022/Matematyka/poziom_podstawowy/EMAP-P0-100-2205.pdf",
    maj2022PR = "http://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2022/Matematyka/poziom_rozszerzony/EMAP-R0-100-2205.pdf",
    maj2021PP = "https://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2021/Matematyka/poziom_podstawowy/EMAP-P0-100-2105.pdf",
    maj2021PR = "http://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2021/Matematyka/poziom_rozszerzony/EMAP-R0-100-2105.pdf",
    maj2020PP = "http://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2020/formula_od_2015/matematyka/MMA-P1_1P-202.pdf",
    maj2020PR = "http://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2020/formula_od_2015/matematyka/MMA-R1_1P-202.pdf",
    maj2019PP = "http://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2019/formula_od_2015/matematyka/MMA-P1_1P-192.pdf",
    maj2019PR = "http://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2019/formula_od_2015/matematyka/MMA-R1_1P-192.pdf",
    maj2018PP = "http://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2018/formula_od_2015/matematyka/MMA-P1_1P-182.pdf",
    maj2018PR = "http://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2018/formula_od_2015/matematyka/MMA-R1_1P-182.pdf",
    maj2017PP = "http://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2017/formula_od_2015/matematyka/MMA-P1_1P-172.pdf",
    maj2017PR = "http://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2017/formula_od_2015/matematyka/MMA-R1_1P-172.pdf",
    maj2016PP = "http://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2016/formula_od_2015/MMA-P1_1P-162.pdf",
    maj2016PR = "http://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2016/formula_od_2015/MMA-R1_1P-162.pdf",
    maj2015PP = "https://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2015/formula_od_2015/MMA-P1_1P-152.pdf",
    maj2015PR = "https://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2015/formula_od_2015/MMA-R1_1P-152.pdf",
    wzoryMatematyczne = "https://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Informatory/2015/MATURA_2015_Wybrane_wzory_matematyczne.pdf",

}
export enum CKElinks2005 {
    maj2020PP = "http://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2020/formula_do_2014/matematyka/MMA-P1_1P-202s.pdf",
    maj2020PR = "http://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2020/formula_do_2014/jezyk_polski/MPO-R1_1P-202s.pdf",
    maj2019PP = "http://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2019/formula_do_2014/matematyka/MMA-P1_1P-192.pdf",
    maj2019PR = "http://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2019/formula_do_2014/matematyka/MMA-R1_1P-192.pdf",
    maj2018PP = "http://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2018/formula_do_2014/matematyka/MMA-P1_1P-182.pdf",
    maj2018PR = "http://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2018/formula_do_2014/matematyka/MMA-R1_1P-182.pdf",
    maj2017PP = "http://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2017/formula_do_2014/matematyka/MMA-P1_1P-172.pdf",
    maj2017PR = "http://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2017/formula_do_2014/matematyka/MMA-R1_1P-172.pdf",
    maj2016PP = "http://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2016/formula_do_2014/MMA-P1_1P-162.pdf",
    maj2016PR = "http://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2016/formula_do_2014/MMA-R1_1P-162.pdf",
    maj2015PP = "https://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2015/formula_do_2014/MMA-P1_1P-152.pdf",
    maj2015PR = "https://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2015/formula_do_2014/MMA-R1_1P-152.pdf",
    maj2014PP = "https://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2014/matematyka_PP_A1.pdf",
    maj2014PR = "https://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2014/matematyka_PR_A1.pdf",
    maj2013PP = "https://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2013/matematyka_PP_A.pdf",
    maj2013PR = "https://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2013/matematyka_PR.pdf",
    maj2012PP = "https://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2012/maj/matm_pp.pdf",
    maj2012PR = "https://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2012/maj/matematyka_pr.pdf",
    maj2011PP = "https://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2011/P/matematyka_pp.pdf",
    maj2011PR = "https://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2011/R/pr_matematyka.pdf",
    maj2010PP = "https://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2010/matematyka_pp.pdf",
    maj2010PR = "https://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2010/matematyka_pr.pdf",
    maj2009PP = "https://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2009/matematyka_pp.pdf",
    maj2009PR = "https://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2009/matematyka_pr.pdf",
    maj2008PP = "https://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2008/mat_pp.pdf",
    maj2008PR = "https://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2008/mat_pr.pdf",
    maj2007PP = "https://cke.gov.pl/images/stories/mat2_07/mat_pp.pdf",
    maj2007PR = "https://cke.gov.pl/images/stories/mat2_07/mat_pr.pdf",
    maj2006PP = "https://cke.gov.pl/images/stories/Matura2006/a1_mat.pdf",
    maj2006PR = "https://cke.gov.pl/images/stories/Matura2006/a2_mat.pdf",
    maj2005PP = "https://cke.gov.pl/images/stories/Matura2005/mat_podst_a1.pdf",
    maj2005PR = "https://cke.gov.pl/images/stories/Matura2005/mat_r_a2.pdf",
    wzoryMatematyczne = "https://oke.waw.pl/new/download/files/File/matura/wzory_tablice/popr_tablice_mat.pdf",
}

export enum CKE8egz {
    maj2023 = "https://cke.gov.pl/images/_EGZAMIN_OSMOKLASISTY/Arkusze-egzaminacyjne/2023/matematyka/OMAP-100-2305.pdf",
    maj2022 = "https://cke.gov.pl/images/_EGZAMIN_OSMOKLASISTY/Arkusze-egzaminacyjne/2022/matematyka/OMAP-100-2205.pdf",
    maj2021 = "https://cke.gov.pl/images/_EGZAMIN_OSMOKLASISTY/Arkusze-egzaminacyjne/2021/matematyka/OMAP-100-X-2105.pdf",
    kwiecien2020 = "https://cke.gov.pl/images/_EGZAMIN_OSMOKLASISTY/Arkusze-egzaminacyjne/2020/matematyka/OMAP-100-2004.pdf",
    kwiecien2019 = "https://cke.gov.pl/images/_EGZAMIN_OSMOKLASISTY/Arkusze-egzaminacyjne/2019/matematyka/Arkusz_OMAP-100-1904.pdf",
}

export enum gimegz {
    kwiecien2019 = "http://cke.gov.pl/images/_EGZAMIN_GIMNAZJALNY/Arkusze-egzaminacyjne/2019/matematyka/Zeszyt_zadan_GM-MX1-192.pdf",
    kwiecien2018 = "http://cke.gov.pl/images/_EGZAMIN_GIMNAZJALNY/Arkusze-egzaminacyjne/2018/matematyka/Zeszyt_zadan_GM-MX1-182.pdf",
    kwiecien2017 = "http://cke.gov.pl/images/_EGZAMIN_GIMNAZJALNY/Arkusze-egzaminacyjne/2017/matematyka/Arkusz_GM-MX1-172.pdf",
    kwiecien2016 = "http://cke.gov.pl/images/_EGZAMIN_GIMNAZJALNY/Arkusze-egzaminacyjne/2016/matematyka/Zeszyt_zadan_GM-MX1-162.pdf",
    kwiecien2015 = "https://cke.gov.pl/images/_EGZAMIN_GIMNAZJALNY/Arkusze-egzaminacyjne/2015/matematyka/Zeszyt_zadan_GM-MX1-152.pdf",
    kwiecien2014 = "https://cke.gov.pl/images/files/gimnazjum/arkusze_egzaminacyjne/gimnazjum_2014/GM-M1-142.pdf",
    kwiecien2013 = "https://cke.gov.pl/files/file/Arkusze-2013/ARKUSZ-GM-M1-132.pdf",
    kwiecien2012 = "https://cke.gov.pl/images/stories/00000000000000000000gim/mat/GM-M1-122.pdf",
}