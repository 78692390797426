import React from "react";
import './style.css';

const PageLoader = () => {
    return (
        <div className="page-loader-container">
            <div className="loader" />
        </div>
    );
};

export default PageLoader;
