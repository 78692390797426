import { FC } from "react";
import Title from "../../../../components/Title/Title";

const PointContent: FC = () => {

  return (
    <>
      <Title text={"Punkt"} type={"main-article"} />
    </>
  );
};

export default PointContent;
